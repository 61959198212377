import React, { createContext, useState, useContext, useEffect } from 'react';
import { ethers } from 'ethers';
import { createWeb3Modal, defaultConfig } from '@web3modal/ethers5/react';
import { abi } from '../ContractABI'; // Adjust the path as needed
import { useWeb3Modal, useWeb3ModalSigner } from '@web3modal/ethers5/react';

const WalletContext = createContext();

export const useWallet = () => useContext(WalletContext);

const contractAddress = '0xF9701B3454c6adc7B11f3328d7199833111b523d';
// Web3Modal configuration
const projectId = 'd1c2210ee614f7c716a2e89e2aa68b50'; // Replace with your WalletConnect project ID

const bscMainnet = {
  chainId: 56, // BSC Mainnet Chain ID
  name: 'Binance Smart Chain Mainnet',
  currency: 'BNB',
  explorerUrl: 'https://bscscan.com',
  rpcUrl: 'https://bsc-dataseed.binance.org/'
};

const metadata = {
  name: 'RABBIT THE MOON',
  description: 'RABBIT THE MOON',
  url: 'https://rabbitthemoon.com/',
  icons: ['https://avatars.mywebsite.com/']
};



const provider = new ethers.providers.JsonRpcProvider(bscMainnet.rpcUrl);
const contract = new ethers.Contract(contractAddress, abi, provider);

export const WalletProvider = ({ children }) => {
  const web3Modal = createWeb3Modal({
    ethersConfig: defaultConfig({ metadata }),
    chains: [bscMainnet],
    projectId
  });

  const { open } = useWeb3Modal(web3Modal);
  const { signer } = useWeb3ModalSigner();
  const [currentUserAddress, setCurrentUserAddress] = useState('');
  const [walletBalance, setWalletBalance] = useState(0);
  const [isWalletConnected, setIsWalletConnected] = useState(false);
  const [isCorrectNetwork, setIsCorrectNetwork] = useState(false);


  const fetchWalletBalance = async () => {
    if (signer) {
      try {
        const balance = await signer.getBalance();
        // Convert the balance to Ether (wei to Ether) and round it to 4 decimal places
        const etherBalance = parseFloat(ethers.utils.formatEther(balance)).toFixed(4);
        setWalletBalance(etherBalance);
      } catch (error) {
        console.error('Error fetching wallet balance:', error);
      }
    }
  };

  

  useEffect(() => {
    const fetchCurrentUserAddress = async () => {
      if (signer) {
        const address = await signer.getAddress();
        setCurrentUserAddress(address);
        console.log("Current User Address:", address); // Add this line for debugging
      }
    };
  
    fetchCurrentUserAddress();
    fetchWalletBalance(); // Add this line to fetch and display wallet balance

  }, [signer]);

  useEffect(() => {
    const subscribeToAccountChanges = () => {
      if (window.ethereum) {
        // Listen for account changes
        window.ethereum.on('accountsChanged', async (accounts) => {
          if (accounts.length > 0) {
            // Update the current user address
            setCurrentUserAddress(accounts[0]);
          } else {
            // Handle the case where the user has disconnected their wallet
            setCurrentUserAddress('');
          
          }
        });
      }
    };
  
    subscribeToAccountChanges();
  
    // Clean up the effect by removing the event listener when the component unmounts
    return () => {
      if (window.ethereum) {
        window.ethereum.removeListener('accountsChanged', subscribeToAccountChanges);
      }
    };
  }, []);
 
  
  useEffect(() => {
    const fetchCurrentUserAddress = async () => {
      if (signer) {
        const address = await signer.getAddress();
        setCurrentUserAddress(address);
        setIsWalletConnected(true); // Set isWalletConnected to true
        // ... rest of the existing code
      }
    };
  
    fetchCurrentUserAddress();
  }, [signer]);
  
  
  
  // Function to handle wallet connect
  const handleWalletConnect = async () => {
    await open();
    await checkAndSwitchNetwork();
    // ...rest of your connection logic
  };
  
  const desiredNetwork = {
    chainId: '0x38',
    chainName: 'Binance Smart Chain Mainnet',
    rpcUrls: [bscMainnet.rpcUrl],
    blockExplorerUrls: [bscMainnet.explorerUrl]
  };

  const checkNetwork = async () => {
    if (signer) {
      try {
        const network = await signer.provider.getNetwork();
        setIsCorrectNetwork(network.chainId === bscMainnet.chainId);
      } catch (error) {
        console.error('Error checking network:', error);
        setIsCorrectNetwork(false);
      }
    }
  };
  
  useEffect(() => {
    checkNetwork();
  
    const handleNetworkChange = () => {
      checkNetwork();
    };
  
    // Subscribe to network change events
    // This part is specifically for MetaMask and similar wallets
    if (window.ethereum) {
      window.ethereum.on('chainChanged', handleNetworkChange);
  
      return () => {
        window.ethereum.removeListener('chainChanged', handleNetworkChange);
      };
    }
    // Note: For wallets like WalletConnect, you'll need to handle this differently
  }, [signer]); // Add 'signer' as a dependency if it's defined in your component's state
  
  
  
  
  const checkAndSwitchNetwork = async () => {
    if (window.ethereum) {
      try {
        const provider = new ethers.providers.Web3Provider(window.ethereum);
        const network = await provider.getNetwork();
  
        if (network.chainId !== parseInt(desiredNetwork.chainId, 16)) {
          try {
            await window.ethereum.request({
              method: 'wallet_switchEthereumChain',
              params: [{ chainId: desiredNetwork.chainId }]
            });
  
            // Refresh the page after successfully switching the network
            window.location.reload();
          } catch (switchError) {
            // This error code indicates that the chain has not been added to MetaMask
            if (switchError.code === 4902) {
              try {
                await window.ethereum.request({
                  method: 'wallet_addEthereumChain',
                  params: [desiredNetwork]
                });
  
                // Consider refreshing the page after adding the new network, if necessary
                // window.location.reload();
              } catch (addError) {
                // Handle errors when adding a new network
                console.error(addError);
              }
            } else {
              console.error(switchError);
            }
          }
        }
      } catch (error) {
        console.error(error);
      }
    } else {
      // Handle the case where the user doesn't have MetaMask installed
      console.error("Ethereum object doesn't exist!");
    }
  };
  
  


  return (
    <WalletContext.Provider value={{ 
      currentUserAddress, 
      walletBalance, 
      isWalletConnected, 
      isCorrectNetwork, 
      handleWalletConnect,
      // ...other states and functions you want to expose...
    }}>
      {children}
    </WalletContext.Provider>
  );
};
