import React, { useState, useEffect } from 'react';
import { ethers } from 'ethers';
import { createWeb3Modal, defaultConfig } from '@web3modal/ethers5/react';
import { useWeb3Modal, useWeb3ModalSigner } from '@web3modal/ethers5/react';
import { abi } from '../ContractABI';
import '../styles/presaleInterface.css';
import '../styles/Lottery.css';
import { saleTokenAddress, saleTokenAbi } from '../saleToken';

const contractAddress = '0xe3c1859195C1900E5D89601564EDC04Ef7BE809d';

// Web3Modal configuration
const projectId = 'd1c2210ee614f7c716a2e89e2aa68b50'; // Replace with your WalletConnect project ID
const infuraUrl = 'https://goerli.infura.io/v3/15083069ebbe40b6947abaabe8a27821'; // Infura URL for Goerli

const bscMainnet = {
  chainId: 56, // BSC Mainnet Chain ID
  name: 'Binance Smart Chain Mainnet',
  currency: 'BNB',
  explorerUrl: 'https://bscscan.com',
  rpcUrl: 'https://bsc-dataseed.binance.org/'
};

const metadata = {
  name: 'RABBIT THE MOON',
  description: 'RABBIT THE MOON',
  url: 'https://rabbitthemoon.com/',
  icons: ['https://avatars.mywebsite.com/']
};


const provider = new ethers.providers.JsonRpcProvider(bscMainnet.rpcUrl);
const contract = new ethers.Contract(contractAddress, abi, provider);

export default function ConnectButton() {
  const web3Modal = createWeb3Modal({
    ethersConfig: defaultConfig({ metadata }),
    chains: [bscMainnet],
    projectId
  });

  const { open } = useWeb3Modal(web3Modal);
  const { signer } = useWeb3ModalSigner();
  const [currentUserAddress, setCurrentUserAddress] = useState('');
  const [walletBalance, setWalletBalance] = useState(0);
  const [purchaseAmount, setPurchaseAmount] = useState('');
  const [currentPrice, setCurrentPrice] = useState('0');
  const [priceInUSD, setPriceInUSD] = useState('0');
  const [priceShift, setPriceShift] = useState(ethers.BigNumber.from(0));
  const nextPrice = parseFloat(priceInUSD) + parseFloat(priceShift);
  const [presaleAmount, setPresaleAmount] = useState(0);
  const [totalTokensSold, setTotalTokensSold] = useState(0);
  const sellPercentage = (totalTokensSold / presaleAmount) * 100;
  const [totalSoldPrice, setTotalSoldPrice] = useState('0');
  const [saleStartTime, setSaleStartTime] = useState(0);
  const [saleEndTime, setSaleEndTime] = useState(0); // Add state for sale end time
  const [remainingTime, setRemainingTime] = useState('');
  const [countdown, setCountdown] = useState('');
  const [daysPast, setDaysPast] = useState(0);
  const [purchasedTokens, setPurchasedTokens] = useState(ethers.BigNumber.from(0));
  const [claimStartTime, setClaimStartTime] = useState(0);
  const [isClaimButtonLocked, setIsClaimButtonLocked] = useState(true);
  const [isWalletConnected, setIsWalletConnected] = useState(false);
  const [isCorrectNetwork, setIsCorrectNetwork] = useState(false);
  const [estimatedCostInUSD, setEstimatedCostInUSD] = useState('0');
  const [buyError, setbuyError] = useState('');
  const [isPresaleEnded, setIsPresaleEnded] = useState(false); // Add state to check if presale has ended
  const [presaleStartTime, setPresaleStartTime] = useState(0); // Add state for presale start time
  const [presaleCountdown, setPresaleCountdown] = useState(''); // Add state for presale countdown
  const [hasPresaleStarted, setHasPresaleStarted] = useState(false); // Add state for presale start check
  const MAX_TOKENS_PER_WALLET = ethers.BigNumber.from("9900000000000");

  const handlePurchaseAmountChange = (e) => {
    const value = e.target.value;
    if (ethers.BigNumber.from(value).lte(MAX_TOKENS_PER_WALLET)) {
      setPurchaseAmount(value);
    } else {
      setPurchaseAmount(MAX_TOKENS_PER_WALLET.toString());
    }
  };

  const calculateEstimatedCostInUSD = () => {
    if (purchaseAmount && priceInUSD) {
      const costInUSD = parseFloat(purchaseAmount) * parseFloat(priceInUSD);
      setEstimatedCostInUSD(costInUSD.toFixed(6));
    }
  };

  useEffect(() => {
    calculateEstimatedCostInUSD();
  }, [purchaseAmount, priceInUSD]);

  const fetchWalletBalance = async () => {
    if (signer) {
      try {
        const balance = await signer.getBalance();
        const etherBalance = parseFloat(ethers.utils.formatEther(balance)).toFixed(4);
        setWalletBalance(etherBalance);
      } catch (error) {
        console.error('Error fetching wallet balance:', error);
      }
    }
  };

  useEffect(() => {
    const fetchCurrentUserAddress = async () => {
      if (signer) {
        const newAddress = await signer.getAddress();
        if (newAddress !== currentUserAddress) {
          setCurrentUserAddress(newAddress);
          console.log("Current User Address:", newAddress);
        }
      }
    };

    fetchCurrentUserAddress();
    fetchWalletBalance();
  }, [signer]);

  useEffect(() => {
    const subscribeToAccountChanges = () => {
      if (window.ethereum) {
        window.ethereum.on('accountsChanged', async (accounts) => {
          if (accounts.length > 0) {
            setCurrentUserAddress(accounts[0]);
          } else {
            setCurrentUserAddress('');
          }
        });
      }
    };

    subscribeToAccountChanges();

    return () => {
      if (window.ethereum) {
        window.ethereum.removeListener('accountsChanged', subscribeToAccountChanges);
      }
    };
  }, []);

  const fetchCurrentPrice = async (tokenAmountString) => {
    try {
      const tokenAmount = tokenAmountString ? ethers.BigNumber.from(tokenAmountString) : ethers.BigNumber.from("1");
      const prices = await contract.getPrice(tokenAmount);
      const formattedPriceInNative = ethers.utils.formatUnits(prices[0], 'ether');
      const formattedPriceInUSD = ethers.utils.formatUnits(prices[1], 18);

      console.log('Fetched priceInUSD:', formattedPriceInUSD);
      setCurrentPrice(formattedPriceInNative);
      setPriceInUSD(formattedPriceInUSD);
    } catch (error) {
      console.error('Error fetching prices:', error);
    }
  };

  useEffect(() => {
    fetchCurrentPrice("1");
  }, []);

  const buyTokensWithNative = async (tokenAmountString) => {
    if (!isWalletConnected || !isCorrectNetwork) {
      setbuyError('Please connect to the wallet and ensure you are on the correct network.');
      return;
    }

    if (!signer) {
      console.error('Wallet is not connected');
      return;
    }

    try {
      const tokenAmount = ethers.BigNumber.from(tokenAmountString);

      const [priceInNativeCoin] = await contract.getPrice(tokenAmount);

      console.log(`Price Per Token in Wei: ${priceInNativeCoin.toString()}`);
      console.log(`Token Amount: ${tokenAmountString}`);

      const totalCostInWei = priceInNativeCoin;

      console.log(`Total cost in Wei: ${totalCostInWei.toString()}`);

      const transactionResponse = await contract.connect(signer).buyWithNativeCoin(
        tokenAmount,
        0,
        {
          value: totalCostInWei.toString(),
          gasLimit: ethers.utils.hexlify(2000000)
        }
      );

      await transactionResponse.wait();
      console.log('Tokens bought successfully');
      fetchWalletBalance();
      fetchTotalTokensSold();
      fetchTotalSoldPrice();
      fetchPurchasedTokens();

    } catch (error) {
      console.error('Error buying tokens:', error);
    }
  };

  const fetchPriceShift = async () => {
    try {
      const shiftValueRaw = await contract.priceShift();
      const priceShiftValue = ethers.BigNumber.from(shiftValueRaw);
      const formattedShiftValue = ethers.utils.formatUnits(priceShiftValue, 18);
      setPriceShift(formattedShiftValue);
    } catch (error) {
      console.error('Error fetching price shift:', error);
    }
  };

  useEffect(() => {
    fetchCurrentPrice("1");
    fetchPriceShift();
  }, []);

  const fetchPresaleAmount = async () => {
    try {
      const result = await contract.presaleAmount();
      const presaleAmount = parseInt(result.toString());
      setPresaleAmount(presaleAmount);
    } catch (error) {
      console.error('Error fetching presale amount:', error);
    }
  };

  useEffect(() => {
    fetchPresaleAmount();
  }, []);

  const fetchTotalTokensSold = async () => {
    try {
      const result = await contract.totalTokensSold();
      const tokensSold = parseInt(result.toString());
      setTotalTokensSold(tokensSold);
    } catch (error) {
      console.error('Error fetching totalTokensSold:', error);
    }
  };

  useEffect(() => {
    fetchTotalTokensSold();
  }, []);

  const fetchTotalSoldPrice = async () => {
    try {
      const result = await contract.totalSoldPrice();
      const soldPrice = ethers.utils.formatUnits(result, 18);
      setTotalSoldPrice(soldPrice);
    } catch (error) {
      console.error('Error fetching totalSoldPrice:', error);
    }
  };

  useEffect(() => {
    fetchTotalSoldPrice();
  }, []);

  const fetchSaleStartTime = async () => {
    try {
      const startTime = await contract.saleStartTime();
      setSaleStartTime(Number(startTime));
      fetchDaysPast();
    } catch (error) {
      console.error('Error fetching sale start time:', error);
    }
  };

  const fetchSaleEndTime = async () => {
    try {
      const endTime = await contract.saleEndTime();
      setSaleEndTime(Number(endTime));
      checkIfPresaleEnded(Number(endTime));
    } catch (error) {
      console.error('Error fetching sale end time:', error);
    }
  };

  const checkIfPresaleEnded = (endTime) => {
    const currentTime = Math.floor(Date.now() / 1000);
    if (currentTime >= endTime) {
      setIsPresaleEnded(true);
    } else {
      setIsPresaleEnded(false);
    }
  };

  useEffect(() => {
    fetchSaleStartTime();
    fetchSaleEndTime();
  }, []);

  const fetchDaysPast = async () => {
    try {
      const daysPastFromContract = await contract.getDaysPast();
      setDaysPast(daysPastFromContract.toNumber());
    } catch (error) {
      console.error('Error fetching days past:', error);
    }
  };

  const calculateRemainingTime = (daysPast) => {
    const currentTime = new Date();
    const saleStartDate = new Date(saleStartTime * 1000);
    let nextShiftTime = new Date(saleStartDate.getTime() + daysPast * 24 * 60 * 60 * 1000);

    while (nextShiftTime < currentTime) {
      nextShiftTime = new Date(nextShiftTime.getTime() + 24 * 60 * 60 * 1000);
    }

    const timeDiff = nextShiftTime - currentTime;

    const hours = Math.floor(timeDiff / (1000 * 60 * 60));
    const minutes = Math.floor((timeDiff % (1000 * 60 * 60)) / (1000 * 60));

    setRemainingTime(`${hours} Hours ${minutes} Minutes`);
  };

  useEffect(() => {
    let timerInterval;
    const calculateCountdown = () => {
      const currentTime = new Date();
      const saleStartDate = new Date(saleStartTime * 1000);
      let nextShiftTime = new Date(saleStartDate.getTime() + (daysPast + 1) * 24 * 60 * 60 * 1000);

      while (nextShiftTime < currentTime) {
        nextShiftTime = new Date(nextShiftTime.getTime() + 24 * 60 * 60 * 1000);
      }

      const timeDiff = nextShiftTime - currentTime;

      if (timeDiff <= 0) {
        clearInterval(timerInterval);
        setCountdown('00:00:00');
        return;
      }

      const hours = String(Math.floor(timeDiff / (1000 * 60 * 60))).padStart(2, '0');
      const minutes = String(Math.floor((timeDiff % (1000 * 60 * 60)) / (1000 * 60))).padStart(2, '0');
      const seconds = String(Math.floor((timeDiff % (1000 * 60)) / 1000)).padStart(2, '0');

      setCountdown(`${hours}:${minutes}:${seconds}`);
    };

    timerInterval = setInterval(calculateCountdown, 1000);

    return () => clearInterval(timerInterval);
  }, [saleStartTime, daysPast]);

  const fetchPurchasedTokens = async () => {
    if (signer && currentUserAddress) {
      try {
        const tokens = await contract.purchasedTokens(currentUserAddress);
        setPurchasedTokens(ethers.BigNumber.from(tokens));
        console.log('Purchased Tokens:', tokens.toString());
      } catch (error) {
        console.error('Error fetching purchased tokens:', error);
      }
    }
  };

  useEffect(() => {
    fetchPurchasedTokens();
  }, [currentUserAddress]);

  const fetchClaimStartTime = async () => {
    try {
      const startTime = await contract.claimStartTime();
      setClaimStartTime(Number(startTime));
    } catch (error) {
      console.error('Error fetching claim start time:', error);
    }
  };

  useEffect(() => {
    fetchClaimStartTime();
  }, []);

  const checkClaimButtonLock = () => {
    const currentTime = Math.floor(Date.now() / 1000);
    if (currentTime >= claimStartTime) {
      setIsClaimButtonLocked(false);
    } else {
      setIsClaimButtonLocked(true);
    }
  };

  useEffect(() => {
    checkClaimButtonLock();
  }, [claimStartTime]);

  const claimTokens = async () => {
    if (!signer) {
      console.error('Wallet is not connected');
      return;
    }

    if (isClaimButtonLocked) {
      console.error('Claiming is not available at this time');
      return;
    }

    try {
      const gasLimit = 500000;
      const transactionResponse = await contract.connect(signer).claim({
        gasLimit: ethers.utils.hexlify(gasLimit),
      });
      await transactionResponse.wait();
      console.log('Tokens claimed successfully');
    } catch (error) {
      console.error('Error claiming tokens:', error);
    }
  };

  useEffect(() => {
    const fetchCurrentUserAddress = async () => {
      if (signer) {
        const address = await signer.getAddress();
        setCurrentUserAddress(address);
        setIsWalletConnected(true);
      }
    };

    fetchCurrentUserAddress();
  }, [signer]);

  const handleWalletConnect = async () => {
    await open();
    await checkAndSwitchNetwork();
  };

  const desiredNetwork = {
    chainId: '0x38',
    chainName: 'Binance Smart Chain Mainnet',
    rpcUrls: [bscMainnet.rpcUrl],
    blockExplorerUrls: [bscMainnet.explorerUrl]
  };

  const checkNetwork = async () => {
    if (signer) {
      try {
        const network = await signer.provider.getNetwork();
        setIsCorrectNetwork(network.chainId === bscMainnet.chainId);
      } catch (error) {
        console.error('Error checking network:', error);
        setIsCorrectNetwork(false);
      }
    }
  };

  useEffect(() => {
    checkNetwork();

    const handleNetworkChange = () => {
      checkNetwork();
    };

    if (window.ethereum) {
      window.ethereum.on('chainChanged', handleNetworkChange);

      return () => {
        window.ethereum.removeListener('chainChanged', handleNetworkChange);
      };
    }
  }, [signer]);

  const checkAndSwitchNetwork = async () => {
    if (window.ethereum) {
      try {
        const provider = new ethers.providers.Web3Provider(window.ethereum);
        const network = await provider.getNetwork();

        if (network.chainId !== parseInt(desiredNetwork.chainId, 16)) {
          try {
            await window.ethereum.request({
              method: 'wallet_switchEthereumChain',
              params: [{ chainId: desiredNetwork.chainId }]
            });

            window.location.reload();
          } catch (switchError) {
            if (switchError.code === 4902) {
              try {
                await window.ethereum.request({
                  method: 'wallet_addEthereumChain',
                  params: [desiredNetwork]
                });
              } catch (addError) {
                console.error(addError);
              }
            } else {
              console.error(switchError);
            }
          }
        }
      } catch (error) {
        console.error(error);
      }
    } else {
      console.error("Ethereum object doesn't exist!");
    }
  };

  const fetchPresaleStartTime = async () => {
    try {
      const startTime = await contract.saleStartTime();
      setPresaleStartTime(Number(startTime));
    } catch (error) {
      console.error('Error fetching presale start time:', error);
    }
  };

  useEffect(() => {
    fetchPresaleStartTime();
  }, []);

  useEffect(() => {
    if (presaleStartTime > 0) {
      const updateCountdown = () => {
        const currentTime = Math.floor(Date.now() / 1000);
        const timeDiff = presaleStartTime - currentTime;

        if (timeDiff <= 0) {
          setPresaleCountdown('00:00:00');
          setHasPresaleStarted(true); // Set presale as started
        } else {
          const hours = String(Math.floor(timeDiff / 3600)).padStart(2, '0');
          const minutes = String(Math.floor((timeDiff % 3600) / 60)).padStart(2, '0');
          const seconds = String(timeDiff % 60).padStart(2, '0');
          setPresaleCountdown(`${hours}:${minutes}:${seconds}`);
        }
      };

      const interval = setInterval(updateCountdown, 1000);
      return () => clearInterval(interval);
    }
  }, [presaleStartTime]);

  if (!isWalletConnected) {
    return (
      <div className="connect-wallet-center card-container">
        <div className='card'>
          <div className='remaining-time'>
          {!hasPresaleStarted && (
              <div className='remaining-time'>
                <div className='time-label'>PRESALE STARTS IN</div>
                <div className='timer'>
                  <div className='time-unit'>
                    <span className='number'>{presaleCountdown.split(':')[0]}</span>
                    <span className='unit'>Hours</span>
                  </div>
                  <div className='time-unit'>
                    <span className='number'>{presaleCountdown.split(':')[1]}</span>
                    <span className='unit'>Minutes</span>
                  </div>
                  <div className='time-unit'>
                    <span className='number'>{presaleCountdown.split(':')[2]}</span>
                    <span className='unit'>Seconds</span>
                  </div>
                </div>
              </div>
            )}
          {hasPresaleStarted && (
          <div className='remaining-time'>
            <div className='time-label'>NEXT PRICE INCREASE IN</div>
            <div className='timer'>
              <div className='time-unit'>
                <span className='number'>{countdown.split(':')[0]}</span>
                <span className='unit'>Hours</span>
              </div>
              <div className='time-unit'>
                <span className='number'>{countdown.split(':')[1]}</span>
                <span className='unit'>Minutes</span>
              </div>
              <div className='time-unit'>
                <span className='number'>{countdown.split(':')[2]}</span>
                <span className='unit'>Seconds</span>
              </div>
            </div>
          </div>
            )}
            <div className='price-container'>
              <div>
                <div className='price-label'>Current Price</div>
                <div className='price-box current-price'>
                  1 RTM = {Number(priceInUSD).toFixed(14)} USDT
                </div>
              </div>
              <div>
                <div className='price-label'>Next Price</div>
                <div className='price-box next-price-in-usd'>
                  1 RTM = {Number(nextPrice).toFixed(14)} USDT
                </div>
              </div>
            </div>
            
            <div className='remaining-time'>
              <div className='time-label'>PRESALE INFORMATION</div>
              <div className='info'>
                <div className='time-unit'>
                  <span className='number'>{presaleAmount}</span>
                  <span className='unit'>Presale Amount</span>
                </div>
                <div className='time-unit'>
                  <span className='number'>{totalTokensSold}</span>
                  <span className='unit'> Total Tokens Sold</span>
                </div>
              </div>
              <div className='sell-bar'>
                <div className='sell-progress' style={{ width: `${sellPercentage}%` }}>
                  {sellPercentage.toFixed(5)}%
                </div>
              </div>
              <div className='total-sold-price-label'>
                USD Raised:
              </div>
              <div className='total-sold-price'>
                {totalSoldPrice} USD
              </div>
            </div>
            <button className="connect-wallet-button" onClick={handleWalletConnect}>
              Connect Wallet
            </button>
          </div>
        </div>
      </div>
    );
  }

  if (typeof window.ethereum !== 'undefined' && !isCorrectNetwork) {
    return (
      <div className="network-warning">
        <p>Please connect to the BSC Network.</p>
        <button onClick={checkAndSwitchNetwork}>Switch Network</button>
      </div>
    );
  }

  if (isPresaleEnded) {
    return (
      <div className='shimmer-effect'>
        <div className="card-container1 ">
          <div className='presale-ended '>
            <h2>The presale has ended.</h2>
            <p>Thank you for your participation!</p>
            <p>You can now claim your tokens.</p>
            <div className='remaining-time'>
              <div className='time-label'>PRESALE INFORMATION</div>
              <div className='info'>
                <div className='time-unit'>
                  <span className='number'>{presaleAmount}</span>
                  <span className='unit'>Presale Amount</span>
                </div>
                <div className='time-unit'>
                  <span className='number'>{totalTokensSold}</span>
                  <span className='unit'> Total Tokens Sold</span>
                </div>
              </div>
              <div className='sell-bar'>
                <div className='sell-progress' style={{ width: `${sellPercentage}%` }}>
                  {sellPercentage.toFixed(5)}%
                </div>
              </div>
              <div className='total-sold-price-label'>
                USD Raised:
              </div>
              <div className='total-sold-price'>
                {totalSoldPrice} USD
              </div>
            </div>
            <div className='div-purchased-tokens'>
              <p>Purchased Tokens: {purchasedTokens.toString()}</p>
            </div>
            <div className='div-claim-button'>
              <button
                className="claim-button"
                onClick={() => claimTokens()}
                disabled={isClaimButtonLocked}
              >
                Claim Tokens
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className='shimmer-effect'>
      <div className="card-container">
        <div className='card'>
        {!hasPresaleStarted && (
              <div className='remaining-time'>
                <div className='time-label'>PRESALE STARTS IN</div>
                <div className='timer'>
                  <div className='time-unit'>
                    <span className='number'>{presaleCountdown.split(':')[0]}</span>
                    <span className='unit'>Hours</span>
                  </div>
                  <div className='time-unit'>
                    <span className='number'>{presaleCountdown.split(':')[1]}</span>
                    <span className='unit'>Minutes</span>
                  </div>
                  <div className='time-unit'>
                    <span className='number'>{presaleCountdown.split(':')[2]}</span>
                    <span className='unit'>Seconds</span>
                  </div>
                </div>
              </div>
            )}
          {hasPresaleStarted && (
          <div className='remaining-time'>
            <div className='time-label'>NEXT PRICE INCREASE IN</div>
            <div className='timer'>
              <div className='time-unit'>
                <span className='number'>{countdown.split(':')[0]}</span>
                <span className='unit'>Hours</span>
              </div>
              <div className='time-unit'>
                <span className='number'>{countdown.split(':')[1]}</span>
                <span className='unit'>Minutes</span>
              </div>
              <div className='time-unit'>
                <span className='number'>{countdown.split(':')[2]}</span>
                <span className='unit'>Seconds</span>
              </div>
            </div>
          </div>
            )}
          <div className='price-container'>
            <div>
              <div className='price-label'>Current Price</div>
              <div className='price-box current-price'>
                1 RTM = {Number(priceInUSD).toFixed(14)} USDT
              </div>
            </div>
            <div>
              <div className='price-label'>Next Price</div>
              <div className='price-box next-price-in-usd'>
                1 RTM = {Number(nextPrice).toFixed(14)} USDT
              </div>
            </div>
          </div>
          <div className='remaining-time'>
            <div className='time-label'>PRESALE INFORMATION</div>
            <div className='info'>
              <div className='time-unit'>
                <span className='number'>{presaleAmount}</span>
                <span className='unit'>Presale Amount</span>
              </div>
              <div className='time-unit'>
                <span className='number'>{totalTokensSold}</span>
                <span className='unit'> Total Tokens Sold</span>
              </div>
            </div>
            <div className='sell-bar'>
              <div className='sell-progress' style={{ width: `${sellPercentage}%` }}>
                {sellPercentage.toFixed(5)}%
              </div>
            </div>
            <div className='total-sold-price-label'>
              USD Raised:
            </div>
            <div className='total-sold-price'>
              {totalSoldPrice} USD
            </div>
          </div>
          {hasPresaleStarted && (
          <div className='purchase-form'>
            <input
              type="text"
              placeholder="Number of tokens to purchase"
              value={purchaseAmount}
              onChange={handlePurchaseAmountChange}
              disabled={purchasedTokens.gte(MAX_TOKENS_PER_WALLET)}
            />
            <button 
              onClick={() => buyTokensWithNative(purchaseAmount)} 
              disabled={purchasedTokens.gte(MAX_TOKENS_PER_WALLET)}
            >
              Buy Tokens
            </button>
          </div>
          )}
           {hasPresaleStarted && (
          <p>Estimated Cost: {estimatedCostInUSD} USD</p>
          )}
          {buyError && <p className="error-message">{buyError}</p>}
          <div className='div-purchased-tokens'>
            <p>Purchased Tokens: {purchasedTokens.toString()}</p>
          </div>
          
          <div className='div-claim-button'>
            <button
              className="claim-button"
              onClick={() => claimTokens()}
              disabled={isClaimButtonLocked}
            >
              Claim Tokens
            </button>
          </div>
          <div className='claim-start-date'>
            Claim Start Date: {new Date(claimStartTime * 1000).toLocaleDateString()}
          </div>
          
        </div>
      </div>
    </div>
  );
}
