// src/pages/LotteryPage.js
import React, { useState } from 'react';
import PresaleInterface from '../components/presaleInterface';
import { Link } from 'react-router-dom';
import '../styles/HomePage.css';
import '../styles/Dashboard.css';
import smalllogo from '../assets/logo 2.png';
import ConnectButton from '../components/ConnectButton'; // Adjust the path as necessary


const LotteryPage = () => {
  const [menuOpen, setMenuOpen] = useState(false);

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };
  return (
    <div>
    <header className="main-header">
        <div className="container">
            <div className="header-section left">
                <img src={smalllogo} alt="Logo" />
            </div>

            <nav className={`nav-links button ${menuOpen ? 'open button' : ''}`}>
    <a href="https://rabbitthemoon.com"  rel="noopener noreferrer">Home</a>
</nav>


            <div className="header-section right">
                <ConnectButton onClick={toggleMenu} />
            </div>

            <div className="hamburger-menu" onClick={toggleMenu}>
                <span></span>
                <span></span>
                <span></span>
            </div>
        </div>

        <div className={`mobile-menu ${menuOpen ? 'open' : ''}`}>
        <button className="close-menu" onClick={toggleMenu}>&times;</button>
        <a href="https://rabbitthemoon.com" className="button"rel="noopener noreferrer">Home</a>
        <ConnectButton onClick={toggleMenu} />
        </div>
    </header>
    <PresaleInterface />
</div>

  );
};

export default LotteryPage;
